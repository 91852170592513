<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <div class="p-1">
                        <form class="" @submit.prevent="submitForm">
                            <div class="row">
                                <div class="form-group col-12 col-md-4">
                                    <label for="phone24">Nombre:</label>
                                    <b-form-input v-model="project_manager.name" autocomplete="off"/>
                                    <span v-if="validation_project_manager.name" class="text-danger">
                                      {{ validation_project_manager.name }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <button type="submit" class="btn btn-primary col-12 col-md-1 ml-1 mt-2">
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import {BCard, BCol, BOverlay, BRow, BFormInput} from 'bootstrap-vue'
import {mapState} from "vuex"
import {VHE_PROJECT_MANAGER_LIST} from "@/router/web/constants"
import {createHelpers} from "vuex-map-fields"

import helper from "@/resources/helpers/helpers"

const {mapFields} = createHelpers({
    getterType: 'auxiliaries2ProjectManagerVhe/getField',
    mutationType: 'auxiliaries2ProjectManagerVhe/updateField',
})

export default {
    name: "FormView",
    components: {
        BCol,
        BRow,
        BCard,
        BFormInput,
        BOverlay,
    },
    data() {
        return {
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('auxiliaries2ProjectManagerVhe', ['validation_project_manager']),
        ...mapFields({
          project_manager: 'project_manager',
        }),
    },
    created() {
        this.$store.commit('auxiliaries2ProjectManagerVhe/RESET_STATE')
        this.$store.commit('auxiliaries2ProjectManagerVhe/CLEAR_VALIDATION')

        if (this.$route.params.id) {
            this.edit = true
            this.getProjectManager()
        } else {
            this.edit = false
        }
        this.$root.$refs.auxiliariesProjectManagerForm = this
    },
    methods: {
      getProjectManager() {

            this.showLoading = true

            this.$store.dispatch('auxiliaries2ProjectManagerVhe/findProjectManager', {
                id: this.$route.params.id ?? this.project_manager.id
            })
                .then(response => {
                  this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        cancel() {
            this.$router.push(VHE_PROJECT_MANAGER_LIST)
        },
        createAuxiliar() {
            this.$store.dispatch('auxiliaries2ProjectManagerVhe/createProjectManager', this.project_manager)
                .then(response => {
                    helper.showToast('Proyecto creado correctamente!', 'CheckIcon', 'success')
                    this.$router.replace(VHE_PROJECT_MANAGER_LIST)
                })
                .catch(error => {
                    helper.showToast('Error al crear proyecto', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
        updateAuxiliar() {
            this.$store.dispatch('auxiliaries2ProjectManagerVhe/updateProjectManager', this.project_manager)
                .then(response => {
                    helper.showToast('Proyecto editado correctamente!', 'CheckIcon', 'success')
                    this.$router.replace(VHE_PROJECT_MANAGER_LIST)
                })
                .catch(error => {
                    helper.showToast('Error al editar proyecto', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
        submitForm() {
            if (this.$route.params.id) {
                this.updateAuxiliar()
            } else {
                this.createAuxiliar()
            }
        },
    },
}
</script>
